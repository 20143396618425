import { BrowserRouter, Route, Routes } from "react-router-dom";

import DashboardPage from "../pages/DashboardPage/DashboardPage";
import EmployeePage from "../pages/EmployeePage/EmployeePage";
import Clinics from "../pages/Settings/components/Clinics/Clinics";

const Router = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<DashboardPage {...props} />} />

        <Route path="/" element={<DashboardPage />} />
        <Route path="/clinics" element={<Clinics />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/EmployeePage" element={<EmployeePage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
