import { all, fork } from 'redux-saga/effects';

import {
  watchAuthenticateUser,
  watchGetUsersList,
  watchDeleteUser,
  watchGetClinicSaga,
  watchUpdateUser,
  watchAddUser,
  watchGetNurses,
  watchGetProducts,
  watchDeleteProducts,
  watchUpdateProducts,
  watchGetPatient,
  watchGetUserRoles,
  watchUpdatePatient,
  watchAddPatient,
  watchSearchPatient,

  watchDeleteAppointment,
  watchGetAppointment,
  watchSaveAppointmentAdditionalDetails,
  watchRefreshAppointment,
  watchCreateLocalAppointment,
  watchGetUserClinic,
  watchDiscardPatient,
  watchNavigateToTreatment,
  watchCreateTreatment,
  watchUpdateTreatment,
  watchConfimrAppointmentTreatments,
  watchApproveTreatment,
  watchGetTreatment,
  watchGetAvailablePrescribers,
  watchGetAcsIdToken,
  watchGetAcsToken,
  watchAssignPresciber,
  watchGetPresciberTreatment,
  watchStartCall,
  watchEndCall,
  watchPatienConsent,
  watchGetDrugBookReceived,
  watchGetDrugBookAdministered,
  watchAddDrugBookAdministered,
  watchAddReceivedDrugBook,
  watchGetDrugBookRemoved,
  watchAddRemovedDrugBook,
  watchDeclineTreatmentPlan,
  watchChangePrescriberStatus,
  watchGetPrescriberDashboard,
  watchConfirmTreatment,
  watchSubmit,
  watchRejoin,
  watchGenerateDocuments,
  watchGetBillingReport,
  watchGetCallPerformanceReport,
  watchSearchAppointments,
  watchGetAdverseEvents,
  watchGetAdverseEvent,
  watchCreateAdverseEvent,
  watchEmailBillingReport,
  watchEmailCallPerformanceReport,
  watchSaveMedicalHistory,
  watchNavigateToAdverseEvent
} from './watchers';

export default function* startRootSaga(): Generator<any, any, any> {
  yield all([
    fork(watchAuthenticateUser),
    fork(watchGetUsersList),
    fork(watchDeleteUser),
    fork(watchGetClinicSaga),
    fork(watchUpdateUser),
    fork(watchAddUser),
    fork(watchGetProducts),
    fork(watchDeleteProducts),
    fork(watchUpdateProducts),
    fork(watchGetPatient),
    fork(watchGetUserRoles),
    fork(watchUpdatePatient),
    fork(watchAddPatient),
    fork(watchSearchPatient),
    fork(watchDeleteAppointment),
    fork(watchGetAppointment),
    fork(watchRefreshAppointment),    
    fork(watchCreateLocalAppointment),
    fork(watchSaveAppointmentAdditionalDetails),
    fork(watchGetUserClinic),
    fork(watchDiscardPatient),
    fork(watchNavigateToTreatment),
    fork(watchCreateTreatment),
    fork(watchUpdateTreatment),
    fork(watchConfimrAppointmentTreatments),
    fork(watchApproveTreatment),
    fork(watchGetTreatment),
    fork(watchPatienConsent),
    fork(watchGetAvailablePrescribers),
    fork(watchGetAcsIdToken),
    fork(watchGetAcsToken),
    fork(watchAssignPresciber),
    fork(watchGetPresciberTreatment),
    fork(watchStartCall),
    fork(watchEndCall),
    fork(watchGetNurses),
    fork(watchGetDrugBookReceived),
    fork(watchGetDrugBookAdministered),
    fork(watchAddDrugBookAdministered),
    fork(watchAddReceivedDrugBook),
    fork(watchGetDrugBookRemoved),
    fork(watchAddRemovedDrugBook),
    fork(watchDeclineTreatmentPlan),
    fork(watchChangePrescriberStatus),
    fork(watchGetPrescriberDashboard),
    fork(watchConfirmTreatment),
    fork(watchSubmit),
    fork(watchRejoin),
    fork(watchGenerateDocuments),
    fork(watchGetBillingReport),
    fork(watchGetCallPerformanceReport),
    fork(watchSearchAppointments),
    fork(watchGetAdverseEvents),
    fork(watchGetAdverseEvent),
    fork(watchCreateAdverseEvent),
    fork(watchEmailBillingReport),
    fork(watchEmailCallPerformanceReport),
    fork(watchSaveMedicalHistory),
    fork(watchNavigateToAdverseEvent),
  ]);
}
