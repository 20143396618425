import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Outlet } from "react-router-dom";
import SideNavigation from "../../components/SideNavigation/SideNavigation";
import Header from "../Header/Header";

function App(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
      <Header {...props} />
      </Grid>
      <Grid item xs={12} md={2}>
        <SideNavigation />
      </Grid>
      <Grid item xs={12} md={10}>
        <Box>
          <Outlet />
        </Box>
      </Grid>
    </Grid>
  );
}

export default App;
