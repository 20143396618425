import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {};

const clinicSlice = createSlice({
  name: 'clinic',
  initialState,
  reducers: {
    getClinics(state, action) {},
    getClinicsSuccess(state, action) {
      const clinicsData = action.payload;
      let clinics = {
        clinics: [...action.payload],
      };
      return { ...state, ...clinics };
    },
   
  },
});


export const { getClinics, getClinicsSuccess } =
  clinicSlice.actions;

export default clinicSlice.reducer;
