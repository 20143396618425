import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import axios from "axios";
import { envEndPoints } from "../../app/config";
import logo from "../../assets/images/logo.svg";
import { Roles } from "../../constants";
import "./Header.css";

const Header = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const { instance } = useMsal();

  const authenticationSelector = useSelector((state) => state.authentication);

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    if (authenticationSelector.role === Roles.Prescriber) {
      let userUrl = `${envEndPoints().baseUrl}/user/logoutUser/${
        authenticationSelector.id
      }/1`;
      axios.post(userUrl).then((response) => {
        alert("You will be logged out!");
        instance.logoutRedirect();
        setTimeout(() => {}, 10000);
      });
    } else {
      alert("You will be logged out!");
      instance.logoutRedirect();
      setTimeout(() => {}, 10000);
    }
  };

  return (
    <header className="header">
      <nav className="container navbar">
        <Link to="/" className="logo-wrapper">
          <img src={logo} className="logo" alt="logo" height="50px" />
        </Link>
        <div className="user-dropdown-wrapper">
          <div
            className="navbar-userinfo flex align-center"
            onClick={() => handleMenu()}
          >
            <div className="navbar-username">Hi {props.name}</div>
            <svg
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.78125 9.0625L1.20312 3.48438C1.01172 3.29297 0.916016 3.06055 0.916016 2.78711C0.916016 2.51367 1.01172 2.28125 1.20312 2.08984L2.14648 1.1875C2.33789 0.996094 2.57031 0.900391 2.84375 0.900391C3.11719 0.900391 3.34961 0.996094 3.54102 1.1875L7.47852 5.125L11.416 1.1875C11.6074 0.996094 11.8398 0.900391 12.1133 0.900391C12.3867 0.900391 12.6191 0.996094 12.8105 1.1875L13.7539 2.08984C13.9453 2.28125 14.041 2.51367 14.041 2.78711C14.041 3.06055 13.9453 3.29297 13.7539 3.48438L8.17578 9.0625C7.98438 9.25391 7.75195 9.34961 7.47852 9.34961C7.20508 9.34961 6.97266 9.25391 6.78125 9.0625Z"
                fill="#282829"
              />
            </svg>
          </div>
          {showMenu && (
            <React.Fragment>
              <div className="user-dropdown-menu">
                <div className="user-name-wrapper">
                  <div className="user-fullname">{props.name}</div>
                  <span className="user-role">{props.role}</span>
                </div>
                <div className="logout-btn-wrapper">
                  <a href="#" className="logout-btn" onClick={handleLogout}>
                    Logout
                  </a>
                </div>
              </div>
              <div
                className="user-dropdown-menu-overlay"
                onClick={() => handleMenu()}
              />
            </React.Fragment>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
