import { post, get, put, del } from './restApi';
import { envEndPoints } from '../config';

// export const getPatientsListByClinic = (req: any) =>
//   get({
//     url: `${envEndPoints().baseUrl}/patient/getPatientsList/${req.id}`,
//   });

export const getPatient = (req: any) =>
  get({
    url: `${envEndPoints().baseUrl}/patient/${req.id}`,
  });

export const deletePatient = (req: any) =>
  del({
    url: `${envEndPoints().baseUrl}/patient/${req.id}`,
  });

  export const updatePatient = (req: any) =>
  put({
    url: `${envEndPoints().baseUrl}/patient`,
    data: req,
  });


  export const addPatient = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/patient`,
    data: req,
  });

 export const searchPatients = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/patient/searchPatients`,
    data: req,
  });

  export const getSearchPatient = (req: any) =>
  get({
    url: `${envEndPoints().baseUrl}/patients`,
  });

  export const discardPatient = (req: any) =>
  del({
    url: `${envEndPoints().baseUrl}/patient/discardPatient/${req.id}`,
  });