import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { Button } from "../../components/FormElements";
import "./EmployeePage.scss";
import { useEffect, useState, useRef } from "react";
import { envEndPoints } from "../../app/config";
import { LinearProgress, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom";

const EmployeePage = () => {
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [lastEmployeeSynced, setLastEmployeeSynced] = useState("");
  const [employeesSyncing, setEmployeeSyncing] = useState(false);

  const navigate = useNavigate();
  const intervalRef = useRef(null);

  const checkSyncStatus = async () => {
    const response = await fetch(`${envEndPoints().baseUrl}/Employee/GetEmployeeSyncStatus`);
    if (response.ok) {
      const isSyncing = await response.json();
      setEmployeeSyncing(isSyncing);
    }
  };

  useEffect(() => {
    // Check sync status every 2 seconds
    intervalRef.current = setInterval(() => {
      checkSyncStatus();
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (!employeesSyncing) {
      fetchEmployees();
    }
  }, [employeesSyncing]);


  const fetchEmployees = async () => {
    try {
      const response = await fetch(
        `${envEndPoints().baseUrl}/employee/getEmployees`
      );
      if (response.ok) {
        const data = await response.json();
        setEmployees(data);
      } else {
        console.error("Failed to fetch employees:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const getNotMatchedReason = (row) => {
    console.log(row); // For debugging to see what's coming in the row object.
    if (row.Status === "Unmatched") {
        if (!row.DayforceXRefCode && row.ZenotiEmployeeId) {
            // Further differentiation based on ZenotiTextField1
            if (!row.ZenotiTextField1 || !row.ZenotiTextField1.trim()) {
                return "Please check if Dayforce ID is provided in Zenoti and the employee is present in Dayforce.";
            } else {
                return "Employee not present in Dayforce";
            }
        } else if (!row.ZenotiEmployeeId && row.DayforceXRefCode) {
            return "Employee does not exist in Zenoti";
        }
    }
    return "Something Went Wrong";
};

  const columns = [
    {
      field: "FirstName",
      headerName: "FirstName",
      width: 150,
    },
    {
      field: "LastName",
      headerName: "Surname",
      width: 150,
    },
    {
      field: "DayforceXRefCode",
      headerName: "DF XRefCode",
      width: 150,
      valueFormatter: (params) => {
        const XRefCode = params.value;
        if (!XRefCode) {
          return "Not available";
        }
    
        return XRefCode;
      },
    },    
    {
      field: "ZenotiTextField1",
      headerName: "Zenoti Field",
      width: 150,
      valueFormatter: (params) => {
        const XRefCode = params.value;
        if (!XRefCode) {
          return "Not available";
        }
    
        return XRefCode;
      },
    },    
    {
      field: "Entity",
      headerName: "DF Entity",
      width: 210,
      valueFormatter: (params) => {
        const entity = params.value;
        if (!entity) {
          return "Not available";
        }
    
        return entity;
      },
    },
    {
      field: "Role",
      headerName: "DF Role",
      width: 150,
      valueFormatter: (params) => {
        const entity = params.value;
        if (!entity) {
          return "Not available";
        }
    
        return entity;
      },
    },
    {
      field: "LastModifiedInDF",
      headerName: "Last Updated in DF",
      type: "date",
      sortable: true,
      width: 200,
      editable: true,
      valueGetter: (params) => {
        const dateValue = params.row.LastModifiedInDF;
        return dateValue !== "0001-01-01T00:00:00" ? new Date(dateValue) : null;
      },
      valueFormatter: (params) => {
        const dateValue = params.value;
    
        if (!dateValue || dateValue === "0001-01-01T00:00:00") {
          return "Not available"; 
        }
    
        const date = new Date(dateValue);
        if (isNaN(date.getTime()) || dateValue === "0001-01-01T00:00:00") {
          return "Not available"; 
        }
    
        const options = {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedDate = date.toLocaleString("en-GB", options);
        return formattedDate.replace(" at ", ", ");
      },
    },
    {
      field: "EmployeeStatus",
      headerName: "Employment Status",
      width: 210,
      sortable: true,
      renderCell: (params) => (
        <div
          className={
            params.value === "ACTIVE" ? "icon-matched" : "icon-notmatched"
          }
          
          style={{
            cursor: params.value !== "ACTIVE" ? "pointer" : "default",
          }}
        >
          {params.value ? (
            <>
              <span>{params.value}</span>
              {params.value === "ACTIVE" ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    marginLeft: "7px",
                    fontSize: "20px",
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{
                    marginLeft: "5px",
                    fontSize: "18px",
                  }}
                />
              )}
            </>
          ) : (
            <span>
              Not available
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{
                  marginLeft: "5px",
                  fontSize: "18px",
                }}
              />
            </span>
          )}
        </div>
      ),
    },
    
    
    {
      field: "Status",
      headerName: "Status",
      sortable: true,
      renderCell: (params) => (
        <div
          className={
            params.value === "Matched" ? "icon-matched" : "icon-notmatched"
          }
          onClick={
            params.value !== "Matched"
              ? () => alert(`Not Matched: ${getNotMatchedReason(params.row)}`)
              : undefined
          }
          style={{
            cursor: params.value !== "Matched" ? "pointer" : "default",
          }}
        >
          {params.value ? (
            <>
              <span>{params.value}</span>
              {params.value === "Matched" ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    marginLeft: "7px",
                    color: "#11673e",
                    fontSize: "20px",
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{
                    marginLeft: "5px",
                    color: "#8c0930",
                    fontSize: "18px",
                  }}
                />
              )}
            </>
          ) : (
            <span>
              Not Matched
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{
                  marginLeft: "5px",
                  color: "#8c0930",
                  fontSize: "18px",
                }}
              />
            </span>
          )}
        </div>
      ),
      width: 160,
    },
  ];

  // const Employees = [
  //   {
  //     id: 1,
  //     Name: "Name",
  //     Surname: "Surname",
  //     DFNumber: "DF0001",
  //     Updated: "2021-07-01",
  //     Status: "Not matched",
  //   },
  //   {
  //     id: 2,
  //     Name: "Name",
  //     Surname: "Surname",
  //     DFNumber: "DF0002",
  //     Updated: "2021-07-01",
  //     Status: "Not matched",
  //   },
  //   {
  //     id: 3,
  //     Name: "Name",
  //     Surname: "Surname",
  //     DFNumber: "DF0003",
  //     Updated: "2021-07-01",
  //     Status: "Not matched",
  //   },

  //   {
  //     id: 4,
  //     Name: "Test 1",
  //     Name: "Name",
  //     Surname: "Surname",
  //     DFNumber: "DF0004",
  //     Updated: "2021-07-01",
  //     Status: "Not matched",
  //   },

  //   {
  //     id: 5,
  //     Name: "Name",
  //     Surname: "Surname",
  //     DFNumber: "DF0005",
  //     Updated: "2021-07-01",
  //     Status: "Not matched",
  //   },

  //   {
  //     id: 6,
  //     Name: "Name",
  //     Surname: "Surname",
  //     DFNumber: "DF0006",
  //     Updated: "2021-07-01",
  //     Status: "Not matched",
  //   },
  // ];

  const handleSyncEmployees = async () => {
    try {
      setEmployeeSyncing(true);
      const response = await fetch(
        `${envEndPoints().baseSyncUrl}/Employee/syncEmployees`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setLastEmployeeSynced(new Date().toLocaleString());
        alert("Employees synced successfully.");
      } else {
        throw new Error("Failed to sync employees.");
      }
    } catch (error) {
      console.error("Error syncing employees:", error.message);
      alert("Failed to sync employees. Please try again later.");
    } finally {
      setEmployeeSyncing(false);
      window.location.href = '/';
    }
  };

  const formatDateForExcel = (dateString) => {
    if (dateString === "0001-01-01T00:00:00") {
        return ""; // or "Not available" if you prefer to display that in Excel
    }
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

  const exportToExcel = () => {
    const dataToExport = employees.map(emp => {
        return {
            FirstName: emp.FirstName,
            LastName: emp.LastName,
            DF_XRefCode: emp.DayforceXRefCode,
            ZenotiField: emp.ZenotiTextField1,
            DF_Entity: emp.Entity,
            DF_Role: emp.Role,
            LastUpdatedInDF: formatDateForExcel(emp.LastModifiedInDF),
            Status: emp.Status
        };
    });

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Employees");
    XLSX.writeFile(wb, "employees.xlsx");
};

  return (
    <Grid container spacing={2} sx={{padding:4}}>

      <Grid item xs={12}>
        <h1>Employees</h1>
      </Grid>
      
       <Grid item xs={12} md={6}  sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
      <Button
        onClick={exportToExcel}
        variant="primary"
      >
        Export to Excel
      </Button>
    </Grid>

    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button disabled = {employeesSyncing} size="lg" variant="primary btn-end" onClick={handleSyncEmployees}>
          Sync Now
        </Button>
      </Grid>

      <Grid item xs={12} sx={{ position: "relative", p: 0 }}>
      {employeesSyncing && (
            <div className="sync-overlay">
              <Typography variant="body1" className="progress-text">
                Sync in progress. (The data that you see might be inaccurate)
              </Typography>
              <LinearProgress className="progress-bar" />
              <br></br>
            </div>
          )}
        <Box>
          <DataGrid
          className="data-drid-table"
            rows={employees}
            columns={columns}
            rowsPerPageOptions={[10, 25]}
            getRowId={(row) => row.Id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#183D63",
                color: "white",
                fontWeight: "500",
                fontSize: "18px",
                fontFamily: "Futura",
              },
              ".MuiDataGrid-row": {
                fontFamily: "Futura",
              },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            disableRowSelectionOnClick
          />
           
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmployeePage;
