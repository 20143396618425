import { post, get } from './restApi';
import { envEndPoints } from '../config';

export const getAcsToken = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/VideoConsult/generateOrRefreshAcsIdToken/${
      req.id
    }`,
  });

export const joinQueue = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/VideoConsult/joinQueue`,
    data: req,
  });

export const assignPrescriber = (req: any) =>
  post({
    url: `${envEndPoints().baseUrl}/VideoConsult/assignPrescriber/${
      req.treatmentPlanId
    }`,
  });

export const getPrescriberTreatment = (req: any) =>
post({
  url: `${envEndPoints().baseUrl}/VideoConsult/getPrescriberTreatment/${req.prescriberId}/${req.treatmentPlanId}`,
});

export const startCall = (req: any) =>
post({
  url: `${envEndPoints().baseUrl}/VideoConsult/startcall/${
    req.videoConsultId
  }`,
});

export const endCall = (req: any) =>
post({
  url: `${envEndPoints().baseUrl}/VideoConsult/endcall`,
  data: req,
});

export const changePrescriberStatus = (req: any) =>
post({
  url: `${envEndPoints().baseUrl}/VideoConsult/prescriberStatus`,
  data: req.data,
});

export const getPrescriberDashboard = (req: any) =>
get({
  url: `${envEndPoints().baseUrl}/VideoConsult/getPrescriberDashboard/${req.prescriberId}`,
});

export const unassignPrescriber = (req: any) =>
post({
  url: `${envEndPoints().baseUrl}/VideoConsult/unassignPrescriber/${req.treatmentPlanId}`,
});

  