import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div className="loading-wrapper">
      <div className="loading">
        <svg height="200" width="200">
          <circle className='ring' cx="100" cy="102" r="70" stroke="#0d1f31" fill="none" strokeLinecap="round" />
        </svg>
      </div>
    </div>
  )
};

export default Loading;