import React from 'react';
import '../TextInput/TextInput.css';

const TextArea = React.forwardRef((props, ref) => {
  const { label, controlId, error="", ...otherProps } = props;

  return (
    <div className="floating-input">
      <textarea ref={ref} className="text-input" id={controlId} placeholder=" " {...otherProps} />
      <label className="floating-label" htmlFor={controlId}>{label}</label>
      {error && <span className="errors-message">{error}</span>}
    </div>
  )
});

export default TextArea;