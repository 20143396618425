import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "../layouts/Main/Main";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import EmployeePage from "../pages/EmployeePage/EmployeePage";
import Schedules from "../pages/Schedules/Schedules";
import Clinics from "../pages/Settings/components/Clinics/Clinics";
import Users from "../pages/UsersPage/Users";

const AdminRouters = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main {...props} />}>
          <Route index element={<DashboardPage />} />
          <Route path="/dashboard" element={<DashboardPage />}></Route>
          <Route path="/clinics" element={<Clinics />}></Route>
          <Route path="/users" element={<Users />}></Route>
          <Route path="/schedules" element={<Schedules />}></Route>
          <Route path="/EmployeePage" element={<EmployeePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AdminRouters;
