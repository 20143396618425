import { call, put } from 'redux-saga/effects';
import { getClinics } from '../api/clinicsApi';
import { getClinicsSuccess } from '../reducers/clinicsSlice';
import { setLoader, resetLoader } from '../reducers/loaderSlice';

export function* getClinicsHandler(action: any): Generator<any, any, any> {
  const { reqData, type, history } = action.payload;

  const { response } = yield call(getClinics);
  if (response) {
    yield put(getClinicsSuccess(response.data));
  }
}
