import {
  Grid,
  LinearProgress,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
  faBriefcase,
  faUserClock,
  faUmbrellaBeach,
  faUserAltSlash,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../components/FormElements";
import { envEndPoints } from "../../app/config";
import "./Schedules.css";
import * as XLSX from "xlsx";
import { useHistory, useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const ITEM_HEIGHT = 48;  
const ITEM_PADDING_TOP = 8;

const Schedules = () => {
  const [schedules, setSchedules] = useState([]);
  const [lastScheduleSynced, setLastScheduleSynced] = useState("");
  const [scheduleSyncing, setScheduleSyncing] = useState(false);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [filter, setFilter] = useState("");
  const intervalRef = useRef(null);
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const navigate= useNavigate();

  const [sortModel, setSortModel] = useState([
    { field: "SyncStatus", sort: "desc" },
  ]);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const checkSyncStatus = async () => {
    const response = await fetch(
      `${envEndPoints().baseUrl}/Schedule/GetScheduleSyncStatus`
    );
    if (response.ok) {
      const isSyncing = await response.json();
      setScheduleSyncing(isSyncing);
    }
  };

  
  useEffect(() => {
    // Check sync status every 2 seconds
    intervalRef.current = setInterval(() => {
      checkSyncStatus();
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (!scheduleSyncing) {
      fetchSchedules();
    }
  }, [scheduleSyncing]);

  const fetchSchedules = async () => {
    try {
      const response = await fetch(
        `${envEndPoints().baseUrl}/schedule/getSchedules`
      );
      if (response.ok) {
        const data = await response.json();

        setSchedules(data);
        filterSchedules(data, filter); // Filter schedules immediately after fetching
      } else {
        console.error("Failed to fetch schedules:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  const fetchMatchedClinics = async () => {
    try {
      const response = await fetch(
        `${envEndPoints().baseUrl}/clinic/getMatchedClinics`
      );
      if (response.ok) {
        let data = await response.json();
        data = data.sort((a, b) => a.ZenotiName.localeCompare(b.ZenotiName));
        console.log("Sorted Clinics: ", data);
        setClinics(data);
      } else {
        console.error("Failed to fetch clinics:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching clinics:", error);
    }
  };

  useEffect(() => {
    fetchSchedules();
    fetchMatchedClinics();
  }, []);

  const handleFilterChange = (filterModel) => {
    // Convert filter model into an object with field names as keys and filter values as values.
    const newFilters = filterModel.items.reduce((acc, item) => {
      if (item.value !== undefined) {
        acc[item.field] = item.value;
      }
      return acc;
    }, {});

    // Apply the new filters to the schedules.
    filterSchedules(schedules, newFilters);
  };

  const filterSchedules = (schedules, filters) => {
    const filtered = schedules.filter((schedule) => {
      // Check for each field in the filters object if it matches the schedule's corresponding value.
      return Object.entries(filters).every(([key, value]) => {
        const itemValue = schedule[key]?.toString().toLowerCase() ?? "";
        const filterValue = value.toLowerCase();
        return itemValue.includes(filterValue);
      });
    });

    // Update the filtered schedules state.
    setFilteredSchedules(filtered);
  };

  // const handleSyncSchedules = async () => {
  //   setScheduleSyncing(true);
  //   try {
  //     const response = await fetch(
  //       `${envEndPoints().baseSyncUrl}/Schedule/syncSchedules`,
  //       {
  //         method: "POST",
  //       }
  //     );
  //     if (response.ok) {
  //       setLastScheduleSynced(new Date().toLocaleString());
  //       alert("Schedules synced successfully.");
  //     } else {
  //       throw new Error("Failed to sync schedules.");
  //     }
  //   } catch (error) {
  //     console.error("Error syncing schedules:", error.message);
  //     alert("Failed to sync schedules. Please try again later.");
  //   } finally {
  //     setScheduleSyncing(false);
  //     window.location.reload();
  //   }
  // };

  const handleSyncSchedules = async () => {
    setScheduleSyncing(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Clinics: selectedClinics })
      };
      const response = await fetch(`${envEndPoints().baseSyncUrl}/Schedule/syncSchedules`, requestOptions);
      if (response.ok) {
        setLastScheduleSynced(new Date().toLocaleString());
        alert("Schedules synced successfully for selected clinics.");
      } else {
        throw new Error("Failed to sync schedules.");
      }
    } catch (error) {
      console.error("Error syncing schedules:", error.message);
      alert("Failed to sync schedules. Please try again later.");
    } finally {
      setScheduleSyncing(false);
      // window.location.reload();
      window.location.href = '/';
    }
  };
  
  const getNotMatchedReason = (row) => {
    if (row.MatchStatus === "Unmatched") {
      return "Employee not matched in Zenoti and Dayforce: Please check the Employee tab for more details.";
    }
  };

  const getNotSyncedReason = (row) => {
    const matchStatus = row.MatchStatus || "Unmatched";
    if (matchStatus === "Unmatched") {
      if (row.DayforceXRefCode === null && row.ZenotiEmployeeId !== null) {
        return "Employee not present in Dayforce";
      } else if (
        row.DayforceXRefCode !== null &&
        row.ZenotiEmployeeId === null
      ) {
        return "Employee not present in Zenoti";
      }
    } else if (row.MatchStatus === "Matched") {
      let message = "";
      if (row.ZenotiScheduleId === null) {
        message = "No schedule available in Zenoti";
      }
      if (row.DayforceStartTime === null && row.DayforceEndTime === null) {
        message +=
          (message ? " and " : "") + "No schedule available in Dayforce";
      }
      return message;
    }

    return row.Message || "Something went wrong";
  };

  const columns = [
    {
      field: "EmployeeName",
      headerName: "Employee",
      width: 130,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "DayforceXRefCode",
      headerName: "Employee Code",
      width: 130,
      headerAlign: "center",
      align: "center",
      sortable: true,
      valueFormatter: (params) => {
        const DayforceXRefCode = params.value;
        return DayforceXRefCode ? DayforceXRefCode : "Not available";
      },
    },
    {
      field: "Date",
      headerName: "Shift Date",
      type: "dateTime",
      width: 130,
      headerAlign: "center",
      align: "center",
      sortable: true,
      valueGetter: (params) => {
        const dateValue = params.row.Date;
        if (!dateValue || dateValue === "0001-01-01T00:00:00") {
          return null;
        }
        const date = new Date(dateValue);
        return isNaN(date.getTime()) ? null : date;
      },
      valueFormatter: (params) => {
        const dateValue = params.value;
        if (!dateValue) {
          return "Not available";
        }
        const options = {
          day: "2-digit",
          month: "long",
          year: "numeric",
        };
        const formattedDate = dateValue.toLocaleString("en-GB", options);
        return formattedDate;
      },
    },
    {
      field: "DayforceStartTime",
      headerName: "Shift Starts",
      type: "dateTime",
      width: 130,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        const shiftDate = params.row.Date;
        const dateValue = params.row.DayforceStartTime;
        if (
          !shiftDate ||
          shiftDate === "0001-01-01T00:00:00" ||
          new Date(shiftDate).getTime() === 0 ||
          !dateValue ||
          dateValue === "0001-01-01T00:00:00"
        ) {
          return null;
        }
        return new Date(dateValue);
      },
      valueFormatter: (params) => {
        const dateValue = params.value;
        if (!dateValue || isNaN(dateValue.getTime())) {
          return "Not available";
        }
        const options = {
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedTime = dateValue.toLocaleTimeString("en-US", options);
        return formattedTime;
      },
    },
    {
      field: "DayforceEndTime",
      headerName: "Shift Ends",
      type: "dateTime",
      width: 130,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        const shiftDate = params.row.Date;
        const dateValue = params.row.DayforceEndTime;
        if (
          !shiftDate ||
          shiftDate === "0001-01-01T00:00:00" ||
          new Date(shiftDate).getTime() === 0 ||
          !dateValue ||
          dateValue === "0001-01-01T00:00:00"
        ) {
          return null;
        }
        return new Date(dateValue);
      },
      valueFormatter: (params) => {
        const dateValue = params.value;
        if (!dateValue || isNaN(dateValue.getTime())) {
          return "Not available";
        }
        const options = {
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedTime = dateValue.toLocaleTimeString("en-US", options);
        return formattedTime;
      },
    },
    {
      field: "ClinicName",
      headerName: "Clinic",
      width: 120,
      headerAlign: "center",
      align: "center",
      sortable: true,
      valueFormatter: (params) => {
        const clinicName = params.value;
        return clinicName ? clinicName : "Not available";
      },
    },
    {
      field: "SyncStatus",
      headerName: "Sync Status",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (params) => (
        <div
          className={
            params.value === "Synced" ? "icon-synced" : "icon-notSynced"
          }
          onClick={
            params.value !== "Synced"
              ? () => alert(`Not Synced: ${getNotSyncedReason(params.row)}`)
              : undefined
          }
          style={{
            cursor: params.value !== "Synced" ? "pointer" : "default",
          }}
        >
          {params.value ? (
            <>
              <span>{params.value}</span>
              {params.value === "Synced" ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    marginLeft: "7px",
                    color: "#11673e",
                    fontSize: "20px",
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{
                    marginLeft: "5px",
                    color: "#8c0930",
                    fontSize: "18px",
                  }}
                />
              )}
            </>
          ) : (
            <span>
              Not Synced
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{
                  marginLeft: "5px",
                  color: "#8c0930",
                  fontSize: "18px",
                }}
              />
            </span>
          )}
        </div>
      ),
    },
    {
      field: "WorkStateName",
      headerName: "Work State",
      width: 160,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (params) => {
        const workStateName = params.value || "Not available";
        let className, icon;
    
        switch (workStateName) {
          case "Not Set":
            className = "yellow-box";
            icon = faCircleXmark;
            break;
          case "Working":
            className = "green-box";
            icon = faUserClock;
            break;
          case "Annual Leave":
            className = "orange-box";
            icon = faUmbrellaBeach;
            break;
          default:
            className = "red-box";
            icon = faUserAltSlash;
        }
    
        return (
          <Box className={className} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography>{workStateName}</Typography>
            <FontAwesomeIcon icon={icon} style={{ marginLeft: "5px" }} />
          </Box>
        );
      },
    },
    
    {
      field: "MatchStatus",
      width: 170,
      headerName: "MatchStatus",
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          className={
            params.value === "Matched" ? "icon-matched" : "icon-notmatched"
          }
          onClick={
            params.value !== "Matched"
              ? () => alert(`${getNotMatchedReason(params.row)}`)
              : undefined
          }
          style={{
            cursor: params.value !== "Matched" ? "pointer" : "default",
          }}
        >
          {params.value ? (
            <>
              <span>{params.value}</span>
              {params.value === "Matched" ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    marginLeft: "7px",
                    color: "#11673e",
                    fontSize: "20px",
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{
                    marginLeft: "5px",
                    color: "#8c0930",
                    fontSize: "18px",
                  }}
                />
              )}
            </>
          ) : (
            <span>
              Not Matched
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{
                  marginLeft: "5px",
                  color: "#8c0930",
                  fontSize: "18px",
                }}
              />
            </span>
          )}
        </div>
      ),
    },
//   {
//     field: "LastSync",
//     headerName: "Last Sync",
//     type: "dateTime",
//     width: 180,
//     headerAlign: "center",
//     align: "center",
//     sortable: true,
//     valueGetter: (params) => {
//         const syncTimeValue = params.row.LastSync;
//         if (!syncTimeValue || syncTimeValue === "0001-01-01T00:00:00") {
//             return null;
//         }
//         return new Date(syncTimeValue);
//     },
//     valueFormatter: (params) => {
//         const syncTimeValue = params.value;
//         if (!syncTimeValue) {
//             return "Not available";
//         }
//         const options = {
//             day: "2-digit",
//             month: "long",
//             year: "numeric",
//             hour: "2-digit",
//             minute: "2-digit",
//         };
//         return syncTimeValue.toLocaleString("en-GB", options);
//     },
// },

];

  const formatDateForExcel = (dateString, isTimeOnly = false) => {
    if (!dateString || dateString === "0001-01-01T00:00:00") {
      return ""; // Return an empty string for non-applicable dates
    }
    const date = new Date(dateString);
    if (isTimeOnly) {
      // Format only the time part (for ShiftStart and ShiftEnd)
      return date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true, // Ensures 12-hour format with AM/PM
      });
    } else {
      // Format the full date-time (for other dateTime fields if any)
      return date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    }
  };

  const exportToExcel = () => {
    const filteredData = filteredSchedules.map((schedule) => {
      return columns.reduce(
        (obj, col) => {
          if (
            col.field === "DayforceStartTime" ||
            col.field === "DayforceEndTime"
          ) {
            obj[col.headerName] = formatDateForExcel(schedule[col.field], true);
          } else if (col.type === "dateTime") {
            obj[col.headerName] = formatDateForExcel(schedule[col.field]);
          } else {
            obj[col.headerName] = schedule[col.field];
          }
          return obj;
        },
        {
          NotSyncedReason: getNotSyncedReason(schedule), // Add Not Synced Reason
          NotMatchedReason: getNotMatchedReason(schedule), // Add Not Matched Reason
        }
      );
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Schedules");
    XLSX.writeFile(wb, "schedules.xlsx");
  };

  // const handleClinicChange = (event) => {
  //   setSelectedClinics(event.target.value);
  // };
  
  const handleClinicChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedClinics(
      // On autofill we get a stringified value, otherwise an array
      typeof value === 'string' ? value.split(',') : value
    );
  };


  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setSelectAll(checked);
    if (checked) {
      setSelectedClinics(clinics); 
    } else {
      setSelectedClinics([]);
    }
  };
  

  return (
    <Grid container spacing={2} sx={{ padding: 4 }}>
     <Grid item xs={12}>
        <Typography variant="h4">Schedules</Typography>
      </Grid>
      <Grid item xs={12} md={6}  sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
      <Button
        onClick={exportToExcel}
        variant="primary"
      >
        Export to Excel
      </Button>
    </Grid>
    {/* <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <FormControl sx={{ width: 300, marginRight: 2 }}>
          <InputLabel id="clinic-select-label">Select Clinics</InputLabel>
          <Select
            labelId="clinic-select-label"
            multiple
            value={selectedClinics}
            onChange={handleClinicChange}
            input={<OutlinedInput label="Select Clinics" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((clinic) => (
                  <Chip key={clinic.Id} label={clinic.ZenotiName} />
                ))}
              </Box>
            )}
          >
            {clinics.map((clinic) => (
              <MenuItem key={clinic.Id} value={clinic}>
                {clinic.ZenotiName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          disabled={scheduleSyncing}
          variant="primary"
          onClick={handleSyncSchedules}
          sx={{ marginLeft: 2 , marginRight: 2}}
        >
          Sync Now
        </Button>
      </Grid> */}
       <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <FormControl sx={{ width: 1000, marginRight: 2 }}>
        <InputLabel id="clinic-select-label">Select Clinics</InputLabel>
          <Select
              labelId="clinic-select-label"
              multiple
              value={selectedClinics}
              onChange={handleClinicChange}
              input={<OutlinedInput label="Select Clinics" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((clinic) => {
                    if (clinic) {
                      return (
                        <Chip key={clinic.Id} label={clinic.ZenotiName} />
                      );
                    }
                    return null; // Return null or an empty element if clinic is not found
                  })}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
                <ListItemText primary="Select All / Deselect All" />
              </MenuItem>
              {clinics.map((clinic) => (
                <MenuItem key={clinic.Id} value={clinic}>
                  <Checkbox checked={selectedClinics.includes(clinic)} />
                  <ListItemText primary={clinic.ZenotiName} />
                </MenuItem>
              ))}
            </Select>

      </FormControl>
      <Button
        disabled={scheduleSyncing}
        variant="primary"
        onClick={handleSyncSchedules}
        sx={{ marginLeft: 2, marginRight: 2 }}
      >
        Sync Now
      </Button>
    </Grid>

      <Grid item xs={12} sx={{ position: "relative", p: 0 }}>
        {scheduleSyncing && (
         <div className="sync-overlay">
         <Typography variant="body1" className="progress-text">
           Sync in progress. (The data that you see might be inaccurate)
         </Typography>
         <LinearProgress className="progress-bar" />
         <br></br>
       </div>
        )}
        <Box sx={{paddingLeft: 0}}>
        <DataGrid
            className="data-drid-table"
            rows={filteredSchedules}
            columns={columns}
            getRowId={(row) => {
              return `${
                row.ZenotiScheduleTableId ||
                row.DayforceScheduleId ||
                row.ZenotiEmployeeId
              }_${Math.random().toString(36).substr(2, 9)}`;
            }}
            filterMode="server" // Ensure the DataGrid knows filtering is handled externally
            onFilterModelChange={handleFilterChange} // Use the updated filter change handler
            pageSize={25}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            sx={{
              width: '100%', // Ensure DataGrid takes full width
              '.MuiDataGrid-root': {
                border: 'none',
                padding:0,
              },
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#183D63",
                color: "white",
                fontWeight: "500",
                fontSize: "18px",
                fontFamily: "Futura",
                border: "none",
                paddingLeft:0,
              },
              ".MuiDataGrid-row": {
                fontFamily: "Futura",
                padding:0,

              },
              ".MuiDataGrid-cell": {
                outline: "none !important",
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Schedules;