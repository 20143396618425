import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useMsal,
  useMsalAuthentication,
  useIsAuthenticated,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import AppShell from './appshell/AppShell';
import AdminShell from './appshell/AdminShell';
import { setAuthenticateUser } from './app/reducers/authenticationSlice';
import NoAccessShell from './appshell/NoAccessShell';
import Loading from './components/Loading/Loading';
import ErrorModal from './components/Modal/ErrorModal';
import { envEndPoints } from './app/config';
import axios from 'axios';
import { Roles } from './constants';
import { loginRequest } from './auth/authConfig';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import theme from './config/theme';

const App = () => {
  const [user, setUser] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  // const [isUserSignExists, setIsUserSignExists] = useState('');


  const dispatch = useDispatch();

  const loaderSelector = useSelector((state) => state.loader);

  const errorSlector = useSelector((state) => state.errorModal);

  useMsalAuthentication(InteractionType.Redirect);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleLogout, 3 * 60 * 60 * 1000);
    };

    resetTimer();

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('mousedown', resetTimer);
    window.addEventListener('keydown', resetTimer);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('mousedown', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, [user]);

  const handleLogout = () => {
    alert("You will be logged out!");
    instance.logoutRedirect();
    setTimeout(() => {
    }, 5000); 
  };

  useEffect(() => {
    if (isAuthenticated) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then(function (accessTokenResponse) {
          let accessToken = accessTokenResponse.accessToken;
          window.sessionStorage.setItem('accessToken', accessToken);
          setUser(accounts[0].username);
          setName(accounts[0].name);
          loadInitialData(accounts, accessToken);
        });
    }
  }, [isAuthenticated, role]);


  useEffect(() => {
    if(!!window.sessionStorage.getItem('accessToken') && !!accounts[0])
    {
      instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then(function (refreshTokenResponse) {
        let accessToken = refreshTokenResponse.accessToken;
      
        const currentToken = window.sessionStorage.getItem('accessToken');
        if(currentToken !== accessToken)
        {
          window.sessionStorage.setItem('accessToken', accessToken);
        }
      });
    }

    window.addEventListener('beforeunload', function (e) {
      e.preventDefault(); 
      e.returnValue = '';
    });

  });
  
  const loadInitialData = async (accounts, accessToken) => {
    //Get User roles
    const response = await axios.post(
      `${envEndPoints().baseUrl}/user/getUserRole`,
      {
        FirstName: accounts[0].name,
        LastName: accounts[0].name,
        AzureAdId: accounts[0].localAccountId,
        AzureAdTenantId: accounts[0].tenantId,
        EmailId: accounts[0].username,
        userRole: 'Admin',
      },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `bearer ${accessToken}`,
        },
      }
    );

    //set Authenticated user to State
    dispatch(
      setAuthenticateUser({
        userDetails: accounts[0],
        userName: accounts[0].name,
        userId: accounts[0].username,
        role: response.data.userRole,
        accessToken: accessToken,
        id: response.data.id,
        contactNumber: response.data.ContactNumber,
      })
    );
    
    setRole(response.data.userRole);

  };

  if (user != '' && role != '') {
    return (
      <ThemeProvider theme={theme}>
      <CssBaseline/> 
      <React.Fragment>
        {role === Roles.Admin && <AdminShell name={name} role={role}/>}        
        {(role === Roles.User) && <AppShell name={name} role={role} />}
        {role === Roles.NoRoleAssigned && (<NoAccessShell name={name} role={role}/>)}
        {!!loaderSelector && !!loaderSelector.isLoader && <Loading />}
        {!!errorSlector && !!errorSlector.isError &&  <ErrorModal
            message={errorSlector.errorMessage}
            title="Error"
          />}
      </React.Fragment>
    </ThemeProvider>

    );
  } else {
    return <Loading />;
  }
};
export default App;
