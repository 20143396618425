import { Link, useNavigate } from "react-router-dom";
import "./SideNavigation.css";

const SideNavigation = () => {
  const navigate = useNavigate();

  const handleManageEmployees = () => {
    navigate("/dashboard");
  };

  const handleReviewEmployees = () => {
    navigate("/employees-review");
  };

  const handleUserClick = () => {
    console.log("user clicked");
    navigate("/users");
  };

  const configurationItems = [
    { id: 1, label: "Master Data", onclick: handleManageEmployees },
    { id: 2, label: "Users", onclick: handleUserClick },
  ];

  const employeeITems = [
    { id: 1, label: "Manage Employees", onclick: handleManageEmployees },
    { id: 2, label: "Review Employees", onclick: handleReviewEmployees },
  ];

  return (
    <div className="side-navigation">
      {/* <Link to="/dashboard" className="nav-link">
        Dashboard
      </Link> */}
      <Link to="/clinics" className="nav-link">
        Clinics
      </Link>
      <Link to="/EmployeePage" className="nav-link">
        Employees
      </Link>
      <Link to="/schedules" className="nav-link">
        Schedules
      </Link>
      {/* <Link to="/users" className="nav-link">
        Users
      </Link> */}
    </div>
  );
};

export default SideNavigation;
