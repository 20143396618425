import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
// import "https://kit.fontawesome.com/520e2c90c4.js";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../../components/FormElements";
import "./Clinics.css";
import { envEndPoints } from "../../../../app/config";
import { Grid, LinearProgress, Typography } from "@mui/material";
import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom";

const Clinics = () => {
  const [clinics, setClinics] = useState([]);
  const [clinicSyncing, setClinicSyncing] = useState(false);
  const [lastClinicSynced, setLastClinicSynced] = useState("");
  const intervalRef = useRef(null);

  const navigate = useNavigate();
  const checkSyncStatus = async () => {
    const response = await fetch(`${envEndPoints().baseUrl}/Clinic/GetClinicSyncStatus`);
    if (response.ok) {
      const isSyncing = await response.json();
      setClinicSyncing(isSyncing);
    }
  };

  useEffect(() => {
    // Check sync status every 2 seconds
    intervalRef.current = setInterval(() => {
      checkSyncStatus();
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (!clinicSyncing) {
      fetchClinics();
    }
  }, [clinicSyncing]);


    const fetchClinics = async () => {
      try {
        const response = await fetch(
          `${envEndPoints().baseUrl}/clinic/getClinics`
        );
        if (response.ok) {
          const data = await response.json();
          setClinics(data);
        } else {
          console.error("Failed to fetch clinics:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching clinics:", error);
      }
    };

    useEffect(() => {
      fetchClinics();
    }, []);


  const columns = [
    {
      field: "DayforceName",
      headerName: "Clinic Name",
      sortable: true,
      width: 250,
      editable: true,
      valueGetter: (params) => {
        const dayforceName = params.row.DayforceName;
        const zenotiName = params.row.ZenotiName;
        return dayforceName ? dayforceName : zenotiName;
      },
    },
    {
      field: "LedgerCode",
      headerName: "DF Ledger Code",
      sortable: true,
      width: 190,
      editable: true,
      renderCell: (params) => params.value ?? "Not provided",
    },
    {
      field: "ZenotiCenterCode",
      headerName: "ZN Center Code",
      sortable: true,
      width: 190,
      editable: true,
      renderCell: (params) => params.value ?? "Not provided",
    },
    {
      field: "DayforceLastModifiedTimeStamp",
      headerName: "Last Updated in DF",
      type: "date",
      sortable: true,
      width: 210,
      editable: true,
      valueGetter: (params) => {
        const dateValue = params.row.DayforceLastModifiedTimeStamp;
        return dateValue !== "0001-01-01T00:00:00" ? new Date(dateValue) : null;
      },
      valueFormatter: (params) => {
        const dateValue = params.value;

        if (!dateValue || dateValue === "0001-01-01T00:00:00") {
          return "Not available"; // Return "Not Available" if the date value is null, undefined, or equal to DateTime.MinValue
        }

        const date = new Date(dateValue);
        if (isNaN(date.getTime()) || dateValue === "0001-01-01T00:00:00") {
          return "Not available"; // Return blank if the date is invalid or equal to DateTime.MinValue
        }

        const options = {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedDate = date.toLocaleString("en-GB", options);
        return formattedDate.replace(" at ", ", ");
      },
    },

    {
      field: "Status",
      headerName: "Status",
      sortable: true,
      width: 190,
      renderCell: (params) => (
        <div
          className={
            params.value == "Matched" ? "icon-matched" : "icon-notmatched"
          }
        >
          {params.value}
        </div>
      ),
    },
  ];

  const handleSyncClinics = async () => {
    try {
      setClinicSyncing(true);
      const response = await fetch(
        `${envEndPoints().baseUrl}/Clinic/syncClinics`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setLastClinicSynced(new Date().toLocaleString());
        alert("Clinics synced successfully.");
      } else {
        throw new Error("Failed to sync clinics.");
      }
    } catch (error) {
      console.error("Error syncing clinics:", error.message);
      alert("Failed to sync clinics. Please try again later.");
    } finally {
      setClinicSyncing(false);
      // window.location.reload();
      window.location.href = '/';
    }
  };

  const exportToExcel = () => {
    const dataToExport = clinics.map(clinic => {
        return columns.reduce((obj, col) => {
            if (col.type === 'date' && clinic[col.field]) {
                // Format date fields for Excel
                obj[col.headerName] = new Date(clinic[col.field]).toLocaleDateString("en-GB", {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                });
            } else {
                // Copy other fields directly
                obj[col.headerName] = clinic[col.field] ?? "Not available";
            }
            return obj;
        }, {});
    });

    // Generate and download Excel file
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Clinics");
    XLSX.writeFile(wb, "clinics.xlsx");
};


  return (
    <Grid container spacing={2} sx={{ padding: 4 }}>
    
      <Grid item xs={12}>
        <Typography variant="h4">Clinics</Typography>
      </Grid>
      
      <Grid item xs={12} md={6}  sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
      <Button
        onClick={exportToExcel}
        variant="primary"
      >
        Export to Excel
      </Button>
    </Grid>

    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button
          disabled = {clinicSyncing}
            size="lg"
            variant="primary btn-end"
            onClick={handleSyncClinics}
          >
            Sync Now
          </Button>
      </Grid>
    
      <Grid item xs={12} sx={{ position: "relative", p: 0 }}>
      {clinicSyncing && (
            <div className="sync-overlay">
              <Typography variant="body1" className="progress-text">
                Sync in progress. (The data that you see might be inaccurate)
              </Typography>
              <LinearProgress className="progress-bar" />
              <br></br>
            </div>
          )}
         <Box sx={{paddingLeft: 0}}>
        <DataGrid
          className="data-drid-table"
            rows={clinics}
            columns={columns}
            rowsPerPageOptions={[10,25, 50,100]}
            getRowId={(row) => row.Id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#183D63",
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
                fontFamily: "Futura",
              },
              ".MuiDataGrid-row": {
                fontFamily: "Futura",
              },
              ".MuiDataGrid-columnHeaderSortIcon": {
                backgroundColor: "#183D63",
                color: "white",
              },
              ".MuiDataGrid-sortIcon": {
                backgroundColor: "#183D63",
                color: "white",
              },
            }}
            disableRowSelectionOnClick
          />
          
        </Box>
      </Grid>
    </Grid>
  );
};

export default Clinics;
