import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from '../../auth/authConfig.tsx';
import { Client } from '@microsoft/microsoft-graph-client';

const msalInstance = new PublicClientApplication(msalConfig);

const Users = () => {
    const [users, setUsers] = useState([]);
    const [azureUsers, setAzureUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/getUsers');
            setUsers(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch users:', error);
            setLoading(false);
        }
    };

    const fetchAzureUsers = async () => {
        try {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                const response = await msalInstance.acquireTokenSilent({ ...loginRequest, account: accounts[0] });
                const client = Client.init({
                    authProvider: (done) => {
                        done(null, response.accessToken);
                    }
                });

                const users = await client.api('/users').select('displayName,mail,jobTitle,id').get();
                setAzureUsers(users.value.map(user => ({
                    email: user.mail,
                    name: user.displayName,
                    id: user.id
                })));
            }
        } catch (error) {
            if (error instanceof Error && error.name === "InteractionRequiredAuthError") {
                await msalInstance.acquireTokenRedirect(loginRequest);
            }
            console.error('Failed to fetch Azure AD users:', error);
        }
    };

    // Existing deleteUser and addUser functions...

    const columns = [
        { field: 'firstName', headerName: 'First Name', width: 150 },
        { field: 'lastName', headerName: 'Last Name', width: 150 },
        { field: 'email', headerName: 'Email Address', width: 200 },
        { field: 'role', headerName: 'Role', width: 130 },
        // Existing delete column...
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <h1>Users</h1>
            <Button onClick={fetchAzureUsers} variant="contained" style={{ marginBottom: '20px' }}>Add New Users</Button>
            <DataGrid rows={users} columns={columns} pageSize={5} loading={loading} />

            {azureUsers.length > 0 && (
                <ul>
                    {azureUsers.map((user, index) => (
                        <li key={index}>{user.email}</li> // Simplified display
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Users;
