//ToDo: need to discuss how to get this environent varibale.
const nodeEnv = process.env.NODE_ENV || 'development';

export const SASS_TOKEN =
  '?sp=racwdl&st=2022-08-10T03:23:48Z&se=2029-11-01T10:23:48Z&sv=2021-06-08&sr=c&sig=HiZnWShEIuU8z73ty%2BcZ8tO4%2BSoT%2FcBZbz%2BW0%2FJrGHg%3D';

// const endPoints = {
//   baseUrl: process.env.REACT_APP_BASE_END_POINT,
// };

const prdEndPoints = {
  baseUrl: 'https://silkdta.com.au/api/api',
  clientId: 'd5e7588d-8399-45d0-8c15-03dc20574d8d',
  authority:
    'https://login.microsoftonline.com/32dfd67c-42d4-473b-8b85-ecf9779aa69f',
  redirectUri: 'https://silkdta.com.au',
  scope: 'dta.api.access',
};

const prdTestEndPoints = {
  baseUrl: 'https://silkdayforce.australiaeast.cloudapp.azure.com/api/api',
  baseSyncUrl: 'https://silkdayforce.australiaeast.cloudapp.azure.com/api/api',
  clientId: 'f836b556-d456-489e-ad2d-d6dda6f030c9',
  authority:
    'https://login.microsoftonline.com/32dfd67c-42d4-473b-8b85-ecf9779aa69f',
  redirectUri: 'https://silkdayforce.australiaeast.cloudapp.azure.com',
  scope: 'access.api',
};

const testEndPoints = {
  baseUrl: 'https://silkdayforcetest.australiaeast.cloudapp.azure.com/api/api',
  baseSyncUrl: 'https://silkdayforcetest.australiaeast.cloudapp.azure.com/api/api',
  clientId: 'f836b556-d456-489e-ad2d-d6dda6f030c9',
  authority:
    'https://login.microsoftonline.com/32dfd67c-42d4-473b-8b85-ecf9779aa69f',
  redirectUri: 'https://silkdayforcetest.australiaeast.cloudapp.azure.com',
  scope: 'access.api',
};


const localEndPoints = {
  baseUrl: 'https://localhost:50701/api',
  baseSyncUrl: 'https://localhost:50701/api',
  clientId: '41aa669d-c486-467e-90c5-4b77281c1841',
  authority:
    'https://login.microsoftonline.com/85a15b6e-60a6-4ad1-abb0-c0712dcf9d62',
  redirectUri: 'http://localhost:3000/',
  scope: 'access.api',
};

const devEndPoints = {
  baseUrl: 'https://localhost:50701/api',
  baseSyncUrl: 'https://localhost:50701/api',
  clientId: '41aa669d-c486-467e-90c5-4b77281c1841',
  authority:
    'https://login.microsoftonline.com/85a15b6e-60a6-4ad1-abb0-c0712dcf9d62',
  redirectUri: 'http://localhost:3000/',
  scope: 'access.api',
};

export const envEndPoints = () => {
  //return devEndPoints; // to run in docker just make it local
  return prdTestEndPoints; // to run in docker just make it local
};
