import { call, put } from 'redux-saga/effects';
import {
  getPatient,
  deletePatient,
  updatePatient,
  addPatient,
  searchPatients,
  discardPatient,
} from '../api/patientsApi';
import { setLoader, resetLoader } from '../reducers/loaderSlice';
import{setError} from '../reducers/errorModalSlice';
import { getPatientSuccess, getSearchPatientSuccess } from '../reducers/patientSlice';

export function* getPatientHandler(action: any): Generator<any, any, any> {
  const { id, navigate, redirectUrl } = action.payload;
  yield put(setLoader());
  const { response } = yield call(getPatient, { id: id });
  if (response) {
    yield put(getPatientSuccess(response.data));
    yield put(resetLoader());
    navigate(redirectUrl);
  }
}

export function* deletePatientHandler(action: any): Generator<any, any, any> {
  const { id, history } = action.payload;
  yield put(setLoader());
  const deleteResponse = yield call(deletePatient, { id: id });
  if (deleteResponse) {
    //   const { response } = yield call(getUsersList, {});
    //   if (response) {
    //     yield put(getUserListSuccess(response.data));
    //     yield put(resetLoader());
    //   }
  }
}

export function* updatePatientHandler(action: any): Generator<any, any, any> {
  yield put(setLoader());
  const updateResponse = yield call(updatePatient, action.payload);
  if (updateResponse) {
    const { response } = yield call(getPatient, { id: action.payload.Id });
    if (response) {
      yield put(getPatientSuccess(response.data));
    }
    yield put(resetLoader());
    yield put(setError({isError: updateResponse.response.data.isError, errorMessage: updateResponse.response.data.message}));
  }
}

export function* addPatientHandler(action: any): Generator<any, any, any> {
  yield put(setLoader());
  const addUserResponse = yield call(addPatient, action.payload);
  if (addUserResponse) {
      yield put(resetLoader());
  }
  yield put(setError({isError: addUserResponse.response.data.isError, errorMessage: addUserResponse.response.data.message}));
}

export function* searchPatientHandler(action: any): Generator<any, any, any> {
  yield put(setLoader());
  const  { response }  = yield call(searchPatients, action.payload);
  if (response) {
      yield put(getSearchPatientSuccess(response.data));
      yield put(resetLoader());
  }
}

export function* discardPatientHandler(action: any): Generator<any, any, any> {
  const { id } = action.payload;
  yield put(setLoader());
  const deleteResponse = yield call(discardPatient, { id: id });
  if (deleteResponse) {
        yield put(resetLoader());
  }
}