import moment from 'moment';

export const dateParser = (date: string) => {
  const parsedDate = { day: '', month: '', year: '' };

  if (Date.parse(date) > 0) {
    const dateArray = date.split('-');
    parsedDate.day = dateArray[2];
    parsedDate.month = dateArray[1];
    parsedDate.year = dateArray[0];
  }
  return parsedDate;
};

export const datefromMoment = (date: any) => {
  let day = date.get('date');
  let month = date.get('month');
  let year = date.get('year');

  day = day < 10 ? `0${day}` : day;

  month = month < 10 ? `0${month}` : month;

  return `${day}-${month}-${year}`;
};

export const datefromString = (date: any) => {
  if (Date.parse(date) > 0) {
    const datePart = date.substring(0, 10);
    const dateArray = datePart.split('-');

    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
  }
  return date;
};

export const momentfromDate = (date: any) => {
  var momentObj = moment(date, 'DD-MM-YYYY');
  return momentObj;
};
export const momentfromString = (date: any) => {
  var momentObj = moment(date, 'YYYY-MM-DD');
  return momentObj;
};

export const dateTimeNow = () => moment().valueOf();

export const currentdate = () => moment().format('DD-MM-YYYY');

export const nowDateTime = () => new Date();

export const dateObjectParser = (date: {
  day: string;
  month: string;
  year: string;
}) => {
  if (
    Object.values(date).length !== 0 &&
    !!date.day &&
    !!date.month &&
    !!date.year
  ) {
    return `${date.year}-${date.month}-${date.day}`;
  }
  return null;
};

export const formatToDatePickerDate = (date: string) => {

  if(!!date && date.length > 0 && date.includes('/')) 
    {
      const dateArray = date.split('/');
      
        var reCreatedDate = new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`); 
        var formatedDate = moment(reCreatedDate).format('DD MMM yyyy');
        return formatedDate;
    }
    else if(!!date && date.length > 0 && date.includes('-'))
    {
     
       var date = date.substring(0, 10);
       const dateArray = date.split('-');
     
        var reCreatedDate = new Date(`${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`); 
        var formatedDate = moment(reCreatedDate).format('DD MMM yyyy');
        return formatedDate;
    }
    else
    {
      return '';
    }
}

export const formatDate = (dateValue: string) => {
  if (!dateValue || dateValue === "0001-01-01T00:00:00") {
    return "Not available"; // Return "Not Available" if the date value is null, undefined, or equal to DateTime.MinValue
  }

  const date = new Date(dateValue);
  if (isNaN(date.getTime()) || dateValue === "0001-01-01T00:00:00") {
    return "Not available"; // Return blank if the date is invalid or equal to DateTime.MinValue
  }

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formattedDate = date.toLocaleString("en-GB", options);
  return formattedDate.replace(" at ", ", ");
};

// export const formatDate = (dateValue: Date) => {
//   dateValue = new Date('2024-03-26 01:23:54.330');
//   if (!dateValue || dateValue.toISOString() === "0001-01-01T00:00:00.000Z") {
//     return "Not available"; // Return "Not Available" if the date value is null, undefined, or equal to DateTime.MinValue
//   }

//   // Convert UTC date to system time zone
//   const currentTimeZoneOffset = dateValue.getTimezoneOffset();

//   // Adjust the UTC date to the local time zone
//   const localDate = new Date(dateValue.getTime() + (currentTimeZoneOffset ));

//   const options: Intl.DateTimeFormatOptions = {
//     day: "2-digit",
//     month: "long",
//     year: "numeric",
//     hour: "2-digit",
//     minute: "2-digit"
//   };

//   const formattedDate = localDate.toLocaleString("en-GB", options);
//   return formattedDate.replace(" at ", ", ");
// };