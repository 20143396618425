import { takeLatest } from 'redux-saga/effects';
import {
  getUsersListHandler,
  deleteUserHandler,
  updateUserHandler,
  addUserHandler,
  getUserRolesHandler,
  getUserClinicHandler,
  getAvailablePrescribersHandler,
  getNursesHandler
} from './userSaga';

import { authenticateUserHandler } from './authenticationSaga';

import { setAuthenticateUser } from '../reducers/authenticationSlice';
import {
  getUsersList,
  deleteUser,
  updateUser,
  addUser,
  getAvailablePrescribers,
  getNurses
} from '../reducers/userSlice';

import { getUserRoles } from '../reducers/userRolesSlice';

import {
  getClinics

} from '../reducers/clinicsSlice';
import {
  getClinicsHandler
} from './clinicsSaga';

import {
  getProducts,
  deleteProduct,
  updateProduct,
} from '../reducers/productSlice';
import {
  getProducsHandler,
  deleteProductHandler,
  updateProductHandler,
} from './productsSaga';

import {
  deleteAppointmentHandler,
  getAppointmentHandler,
  createAppointmentHandler,
  saveAppointmentAdditionalDetailsHandler,
  confirmAppointmentTreatmentsHandler,
  refreshAppointmentHandler,
  createLocalAppointmentHandler,
  confirmTreatmentHandler,
  submitHandler,
  searchAppointmentsHandler,
  saveMedicalHistoryHandler
} from './appointmentSaga';
import {
  deleteAppointment,
  getAppointment,
  createAppointment,
  saveAppointmentAdditionalDetails,
  confirmAppointmentTreatments,  
  refreshAppointment,
  createLocalAppointment,
  confirmTreatment,
  submitDetails,
  searchAppointments,
  saveMedicalHistoryDetails
} from '../reducers/appointmentSlice';
import { addPatient, discardPatient, getPatient, searchPatients, updatePatient } from '../reducers/patientSlice';
import {
  getPatientHandler,
  updatePatientHandler,
  addPatientHandler,
  searchPatientHandler,
  discardPatientHandler,
} from './patientSaga';

import {
  navigateAddTreatment,
  createTreatmentPlan,
  updateTreatmentPlan,
  approveTreatmentPlan,
  getTreatmentPlan,
  patientConsent,
  declineTreatmentPlan,
  rejoin,
  generateDocuments
} from '../reducers/treatmentPlanSlice';
import {
  navigateToTreatmentHandler,
  createTreatmentHandler,
  updateTreatmentHandler,
  approveTreatmentHandler,
  getTreatmentHandler,
  patientConsentHandler,
  declineTreatmentHandler,
  rejoinHandler,
  generateDocumentsHandler
} from './treatmentSaga';

 import { getUserClinic } from '../reducers/userClinicSlice';
import { getAcsIdTokenHandler, assignPrescriberHandler, getPrescriberTreatmentHandler, startCallHandler, endCallHandler, changePrescriberStatusHandler, getPrescriberDashboardHandler } from './videConsultSaga';
import { getAcsIdToken, assignPresciber, getPrescriberTreatment, startCall, endCall, changePrescriberStatus, getPrescriberDashboard } from '../reducers/videoConsultSlice';

import { getAcsToken } from '../reducers/communicationSlice';
import { getAcsTokenHandler } from './communicationSaga';
import { addDrugBookAdministeredHandler, addReceivedDrugBookHandler, addRemovedDrugBookHandler, getDrugBookAdministeredHandler, getDrugBookReceivedHandler, getDrugBookRemovedHandler } from './drugBookSaga';
import { addDrugBookAdministered, addReceivedDrugBook, addRemovedDrugBook, getDrugBookAdministered, getDrugBookReceived, getDrugBookRemoved } from '../reducers/drugbookSlice';
import { emailBillingReport, emailCallPerformanceReport, getBillingReport, getCallPerformanceReport } from '../reducers/reportSlice';
import { emailBillingReportHandler, emailCallPerformanceReportHandler, getBillingReportHandler, getCallPerformanceReportHandler } from './reportSaga';
import { createAdverseEventHandler, getAdverseEventHandler, getAdverseEventsHandler, navigateToAdverseEventHandler } from './adverseEventsSaga';
import { createAdverseEvent, getAdverseEvent, getAdverseEvents, navigateToAdverseEvent } from '../reducers/adverseEventsSlice';


//User Section
export function* watchAuthenticateUser(): Generator<any, any, any> {
  yield takeLatest(setAuthenticateUser.type, authenticateUserHandler);
}

export function* watchGetUsersList(): Generator<any, any, any> {
  yield takeLatest(getUsersList.type, getUsersListHandler);
}

export function* watchDeleteUser(): Generator<any, any, any> {
  yield takeLatest(deleteUser.type, deleteUserHandler);
}

export function* watchUpdateUser(): Generator<any, any, any> {
  yield takeLatest(updateUser.type, updateUserHandler);
}

export function* watchAddUser(): Generator<any, any, any> {
  yield takeLatest(addUser.type, addUserHandler);
}

export function* watchGetUserRoles(): Generator<any, any, any> {
  yield takeLatest(getUserRoles.type, getUserRolesHandler);
}

export function* watchGetUserClinic(): Generator<any, any, any> {
  yield takeLatest(getUserClinic.type, getUserClinicHandler);
}

export function* watchGetAvailablePrescribers(): Generator<any, any, any> {
  yield takeLatest(getAvailablePrescribers.type, getAvailablePrescribersHandler);
}

export function* watchGetNurses(): Generator<any, any, any> {
  yield takeLatest(getNurses.type, getNursesHandler);
}
//End user Section

//Clinic Section
export function* watchGetClinicSaga(): Generator<any, any, any> {
  yield takeLatest(getClinics.type, getClinicsHandler);
}


//End Clininc Section

//Product Section
export function* watchGetProducts(): Generator<any, any, any> {
  yield takeLatest(getProducts.type, getProducsHandler);
}

export function* watchDeleteProducts(): Generator<any, any, any> {
  yield takeLatest(deleteProduct.type, deleteProductHandler);
}

export function* watchUpdateProducts(): Generator<any, any, any> {
  yield takeLatest(updateProduct.type, updateProductHandler);
}
//End Product Section

//Patient Section
export function* watchGetPatient(): Generator<any, any, any> {
  yield takeLatest(getPatient.type, getPatientHandler);
}
export function* watchUpdatePatient(): Generator<any, any, any> {
  yield takeLatest(updatePatient.type, updatePatientHandler);
}
export function* watchAddPatient(): Generator<any, any, any> {
  yield takeLatest(addPatient.type, addPatientHandler);
}

export function* watchSearchPatient(): Generator<any, any, any> {
  yield takeLatest(searchPatients.type, searchPatientHandler);
}

export function* watchDiscardPatient(): Generator<any, any, any> {
  yield takeLatest(discardPatient.type, discardPatientHandler);
}
//End Patient Section

//Appointment Section

export function* watchDeleteAppointment(): Generator<any, any, any> {
  yield takeLatest(deleteAppointment.type, deleteAppointmentHandler);
}

export function* watchGetAppointment(): Generator<any, any, any> {
  yield takeLatest(getAppointment.type, getAppointmentHandler);
}

export function* watchSearchAppointments(): Generator<any, any, any> {
  yield takeLatest(searchAppointments.type, searchAppointmentsHandler);
}

export function* watchCreateAppointment(): Generator<any, any, any> {
  yield takeLatest(createAppointment.type, createAppointmentHandler);
}

export function* watchRefreshAppointment(): Generator<any, any, any> {
  yield takeLatest(refreshAppointment.type, refreshAppointmentHandler);
}

export function* watchCreateLocalAppointment(): Generator<any, any, any> {
  yield takeLatest(createLocalAppointment.type, createLocalAppointmentHandler);
}

export function* watchSaveAppointmentAdditionalDetails(): Generator<
  any,
  any,
  any
> {
  yield takeLatest(
    saveAppointmentAdditionalDetails.type,
    saveAppointmentAdditionalDetailsHandler
  );
}

export function* watchConfimrAppointmentTreatments(): Generator<any, any, any> {
  yield takeLatest(
    confirmAppointmentTreatments.type,
    confirmAppointmentTreatmentsHandler
  );
}

export function* watchConfirmTreatment(): Generator<any, any, any> {
  yield takeLatest(
    confirmTreatment.type,
    confirmTreatmentHandler
  );
}

export function* watchSubmit(): Generator<any, any, any> {
  yield takeLatest( submitDetails.type, submitHandler);
}

export function* watchGenerateDocuments(): Generator<any, any, any> {
  yield takeLatest( generateDocuments.type, generateDocumentsHandler);
}

export function* watchSaveMedicalHistory(): Generator<any, any, any> {
  yield takeLatest( saveMedicalHistoryDetails.type, saveMedicalHistoryHandler);
}

//End Appointment Section

//Start Treatment Section
export function* watchNavigateToTreatment(): Generator<any, any, any> {
  yield takeLatest(navigateAddTreatment.type, navigateToTreatmentHandler);
}

export function* watchCreateTreatment(): Generator<any, any, any> {
  yield takeLatest(createTreatmentPlan.type, createTreatmentHandler);
}

export function* watchUpdateTreatment(): Generator<any, any, any> {
  yield takeLatest(updateTreatmentPlan.type, updateTreatmentHandler);
}

export function* watchApproveTreatment(): Generator<any, any, any> {
  yield takeLatest(approveTreatmentPlan.type, approveTreatmentHandler);
}

export function* watchGetTreatment(): Generator<any, any, any> {
  yield takeLatest(getTreatmentPlan.type, getTreatmentHandler);
}

export function* watchPatienConsent(): Generator<any, any, any> {
  yield takeLatest(patientConsent.type, patientConsentHandler);
}

export function* watchDeclineTreatmentPlan(): Generator<any, any, any> {
  yield takeLatest(declineTreatmentPlan.type, declineTreatmentHandler);
}

//End Section

//Start VideoConsult Section
export function* watchGetAcsIdToken(): Generator<any, any, any> {
  yield takeLatest(getAcsIdToken.type, getAcsIdTokenHandler);
}

//End Section

export function* watchGetAcsToken(): Generator<any, any, any> {
  yield takeLatest(getAcsToken.type, getAcsTokenHandler);
}

export function* watchAssignPresciber(): Generator<any, any, any> {
  yield takeLatest(assignPresciber.type, assignPrescriberHandler);
}

export function* watchRejoin(): Generator<any, any, any> {
  yield takeLatest(rejoin.type, rejoinHandler);
}

export function* watchGetPresciberTreatment(): Generator<any, any, any> {
  yield takeLatest(getPrescriberTreatment.type, getPrescriberTreatmentHandler);
}

export function* watchStartCall(): Generator<any, any, any> {
  yield takeLatest(startCall.type, startCallHandler);
}

export function* watchEndCall(): Generator<any, any, any> {
  yield takeLatest(endCall.type, endCallHandler);
}

export function* watchChangePrescriberStatus(): Generator<any, any, any> {
  yield takeLatest(changePrescriberStatus.type, changePrescriberStatusHandler);
}

export function* watchGetPrescriberDashboard(): Generator<any, any, any> {
  yield takeLatest(getPrescriberDashboard.type, getPrescriberDashboardHandler);
}

export function* watchGetDrugBookReceived(): Generator<any, any, any> {
  yield takeLatest(getDrugBookReceived.type, getDrugBookReceivedHandler);
}

export function* watchGetDrugBookRemoved(): Generator<any, any, any> {
  yield takeLatest(getDrugBookRemoved.type, getDrugBookRemovedHandler);
}

export function* watchGetDrugBookAdministered(): Generator<any, any, any> {
  yield takeLatest(getDrugBookAdministered.type, getDrugBookAdministeredHandler);
}

export function* watchAddDrugBookAdministered(): Generator<any, any, any> {
  yield takeLatest(addDrugBookAdministered.type, addDrugBookAdministeredHandler);
}

export function* watchAddReceivedDrugBook(): Generator<any, any, any> {
  yield takeLatest(addReceivedDrugBook.type, addReceivedDrugBookHandler);
}

export function* watchAddRemovedDrugBook(): Generator<any, any, any> {
  yield takeLatest(addRemovedDrugBook.type, addRemovedDrugBookHandler);
}

//Report
export function* watchGetBillingReport(): Generator<any, any, any> {
  yield takeLatest(getBillingReport.type, getBillingReportHandler);
}

export function* watchGetCallPerformanceReport(): Generator<any, any, any> {
  yield takeLatest(getCallPerformanceReport.type, getCallPerformanceReportHandler);
}

export function* watchEmailBillingReport(): Generator<any, any, any> {
  yield takeLatest(emailBillingReport.type, emailBillingReportHandler);
}

export function* watchEmailCallPerformanceReport(): Generator<any, any, any> {
  yield takeLatest(emailCallPerformanceReport.type, emailCallPerformanceReportHandler);
}

//Adverse Events
export function* watchGetAdverseEvents(): Generator<any, any, any> {
  yield takeLatest(getAdverseEvents.type, getAdverseEventsHandler);
}

export function* watchGetAdverseEvent(): Generator<any, any, any> {
  yield takeLatest(getAdverseEvent.type, getAdverseEventHandler);
}
export function* watchCreateAdverseEvent(): Generator<any, any, any> {
  yield takeLatest(createAdverseEvent.type, createAdverseEventHandler);
}

export function* watchNavigateToAdverseEvent(): Generator<any, any, any> {
  yield takeLatest(navigateToAdverseEvent.type, navigateToAdverseEventHandler);
}
