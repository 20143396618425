import { call, put } from 'redux-saga/effects';
import { getAcsIdToken } from '../api/videoConsultApi';
import {
  assignPrescriber,
  getPrescriberTreatment,
  startCall,
  endCall,
  changePrescriberStatus,
  getPrescriberDashboard,
} from '../api/communicationApi';
import {
  getAcsIdTokenSuccess,
  assignPresciberSuccess,
  getPrescriberTreatmentSuccess,
  endCallSuccess,
  getPrescriberDashboardSuccess,
} from '../reducers/videoConsultSlice';
import {saveMedicalHistoryDetailsSuccess} from '../reducers/appointmentSlice';
import { setLoader, resetLoader } from '../reducers/loaderSlice';
import { getTreatmentPlanSuccess } from '../reducers/treatmentPlanSlice';
import { getPatientSuccess } from '../reducers/patientSlice';
import store from '../store';
import { Roles } from '../../constants';
import { getAcsToken } from './../api/communicationApi';
import { getAcsTokenSuccess } from '../reducers/communicationSlice';

export function* getAcsIdTokenHandler(action: any): Generator<any, any, any> {
  const { response } = yield call(getAcsIdToken);
  if (response) {
    yield put(getAcsIdTokenSuccess(response.data));
  }
}

export function* assignPrescriberHandler(
  action: any
): Generator<any, any, any> {
  const { data, navigate, redirectUrl } = action.payload;
  const { response } = yield call(assignPrescriber, {
    treatmentPlanId: data.treatmentPlanId,
  });
  if (response) {
    yield put(assignPresciberSuccess({ ...response.data }));
  
    if (!!response.data.isPrescriberAssigned) {
      navigate(redirectUrl);
    }
  }
}

export function* getPrescriberTreatmentHandler(
  action: any
): Generator<any, any, any> {
  yield put(setLoader());
  const { data, navigate, redirectUrl } = action.payload;
  const { response } = yield call(getPrescriberTreatment, {
    prescriberId: data.prescriberId,
  });
  yield put(resetLoader());
  if (response) {
    if (response.data.Status === 0) {
      let data = {
        videoConsultingId: response.data.videoConsultingId,
        totalCall: response.data.totalCall,
        availibity: response.data.availibity,
        reqPageRefresh: false,
      };
      
      yield put(getPrescriberTreatmentSuccess({ ...data }));
      yield put(getTreatmentPlanSuccess({ ...response.data.treatmentPlan }));
      yield put(getPatientSuccess({ ...response.data.PatientDetails }));

      navigate(redirectUrl);
    }
  }
}

export function* startCallHandler(action: any): Generator<any, any, any> {
  const { data, navigate, redirectUrl } = action.payload;
  yield put(setLoader());

  const { response } = yield call(getPrescriberTreatment, {
    prescriberId: data.prescriberId,
    treatmentPlanId: data.treatmentPlanId,
  });

  if (response) {
    if (response.data.Status === 0) {
      let data = {
        videoConsultingId: response.data.videoConsultingId,
        totalCall: response.data.totalCall,
        availibity: response.data.availibity,
        reqPageRefresh: false,
      };
      yield put(getPrescriberTreatmentSuccess({ ...data }));
      yield put(getTreatmentPlanSuccess({ ...response.data.treatmentPlan }));

      const stratCalResponse = yield call(startCall, {
        videoConsultId: data.videoConsultingId,
      });
      yield put(resetLoader());
      navigate(redirectUrl);
    }
  }
}

export function* endCallHandler(action: any): Generator<any, any, any> {
  const { data, navigate, redirectUrl } = action.payload;
  const autenticationSate = store.getState().authentication;

  if (autenticationSate.role !== Roles.Prescriber)
    {
      yield put(setLoader());
      const { response } = yield call(endCall, {
        videoConsultId: data.videoConsultId,
        userId: data.userId,
      });
      if (response)
      {
        let data = { reqPageRefresh: true };
        yield put(endCallSuccess({ ...data }));
        yield put(resetLoader());
      }
      navigate(redirectUrl);
    }
    else{
      const { response } = yield call(endCall, {
        videoConsultId: data.videoConsultId,
        userId: data.userId,
      });
      navigate(redirectUrl);
    }
}

export function* changePrescriberStatusHandler(action: any): Generator<any, any, any> {
  const { data } = action.payload;
  yield put(setLoader());
  const { response } = yield call(changePrescriberStatus, { data });
  if (response) {
    yield call(getPrescriberDashboard, { prescriberId: data.prescriberId });
    yield put(resetLoader());
  }
}

export function* getPrescriberDashboardHandler(action: any): Generator<any, any, any> {
  const { data } = action.payload;
  const { response } = yield call(getPrescriberDashboard, { prescriberId: data.prescriberId });
  if (response) {
    yield put(getPrescriberDashboardSuccess(response.data));
  }
}