import React from 'react';
import './NoAccess.css';
import Heading from '../../../components/Heading/Heading';
import deniedIcon from '../../../assets/images/icons/access-denied-icon.svg';
import { SelectBox, TextArea, Button } from '../../../components/FormElements';


const NoAccess = () => {
  const role = [
    { value: 'Admin', label: 'Admin' },
    { value: 'SuperAdmin', label: 'SuperAdmin' },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <Heading title="Access Denied" />

      <div className="access-form-container">
        <div className="denied-icon-wrapper">
          <img src={deniedIcon} alt="Access Denied" />
        </div>
        <div className="access-form-wrapper">
          <div className="font-arial fw-bold">You don’t currently have access.</div>
          <p className="font-arial">If you believe this is a mistake, please request access so we can assign appropriate permissions for you.</p>
          <hr className="divider" />
          <div className="section-heading">Request Access</div>
          <form onSubmit={handleSubmit}>
            <SelectBox
              label="What role best describes you?"
              options={role}
              name="role"
            />
            <TextArea
              label="What role best describes you?"
              controlId="requestMsg"
              name="requestMsg"
            />
            <div className="text-right">
              <Button>Request Access</Button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
};

export default NoAccess;