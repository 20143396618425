import { Grid, LinearProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
// import { Box } from "@mui/system";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Link } from "react-router-dom";
import Button from "../../components/FormElements/Button/Button";
import "./DashboardPage.scss";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./components/SyncOverlay.css";
import { envEndPoints } from "../../app/config";
import { formatDate } from "../../utilities/dateUtils";

const DashboardPage = () => {
  const [clinicSyncing, setClinicSyncing] = useState(false);
  const [employeesSyncing, setEmployeeSyncing] = useState(false);
  const [scheduleSyncing, setScheduleSyncing] = useState(false);

  const [lastClinicSynced, setLastClinicSynced] = useState("");
  const [lastEmployeeSynced, setLastEmployeeSynced] = useState("");
  const [lastScheduleSynced, setLastScheduleSynced] = useState("");

  const [clinicDashboardDetails, setClinicDashboardDetails] = useState(null);
  const [employeeDashboardDetails, setEmployeeDashboardDetails] = useState(null);
  const [scheduleDashboardDetails, setScheduleDashboardDetails] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedClinics, setSelectedClinics] = useState([]);

  const intervalRef = useRef(null);

  const fetchSyncStatus = async () => {
    try {
      const response = await fetch(`${envEndPoints().baseUrl}/Dashboard/getSyncStatusForDashboard`);
      if (response.ok) {
        const { response: syncStatus } = await response.json();
        
        // Assuming the API could return multiple status objects, one for each source
        syncStatus.forEach(status => {
          switch (status.Source) {
            case "Clinic":
              setClinicSyncing(status.IsSyncOn);
              break;
            case "Employee":
              setEmployeeSyncing(status.IsSyncOn);
              break;
            case "Schedule":
              setScheduleSyncing(status.IsSyncOn);
              break;
            default:
              console.log("Unknown sync source");
          }
        });
      } else {
        throw new Error("Failed to fetch sync status");
      }
    } catch (error) {
      console.error('Failed to fetch sync status:', error);
    }
  };
  
  useEffect(() => {
    fetchSyncStatus(); 
  
    intervalRef.current = setInterval(fetchSyncStatus, 1000); 
  
    return () => clearInterval(intervalRef.current); 
  }, []);
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${envEndPoints().baseUrl}/dashboard/getdashboarddetails`);
        if (!response.ok) {
          throw new Error("Failed to fetch dashboard details");
        }
        const data = await response.json();
        setClinicDashboardDetails(data.ClinicDashboardDetails);
        setEmployeeDashboardDetails(data.EmployeeDashboardDetails);
        setScheduleDashboardDetails(data.ScheduleDashboardDetails);
        
        setLastClinicSynced(formatDate(data.ClinicDashboardDetails.LastClinicSynced));
        setLastEmployeeSynced(formatDate(data.EmployeeDashboardDetails.LastEmployeeSynced));
        setLastScheduleSynced(formatDate(data.ScheduleDashboardDetails.LastScheduleSynced));
        // const utcLastEmployeeSynced = new Date(data.EmployeeDashboardDetails.LastEmployeeSynced);
        // setLastEmployeeSynced(utcLastEmployeeSynced.toLocaleString(undefined, { timeZone: 'UTC' }));
      } catch (error) {
        console.error("Error fetching dashboard details:", error);
      }
    };
    fetchData();
  }, []);

  const handleSyncClinics = async () => {
    try {
      setClinicSyncing(true);
      const response = await fetch(
        `${envEndPoints().baseUrl}/Clinic/syncClinics`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setLastClinicSynced(formatDate(new Date().toLocaleString()));
        alert("Clinics synced successfully.");
        window.location.href = '/';
      } else {
        throw new Error("Failed to sync clinics.");
      }
    } catch (error) {
      console.error("Error syncing clinics:", error.message);
      alert("Failed to sync clinics. Please try again later.");
    } finally {
      setClinicSyncing(false);
    }
  };

  const handleSyncEmployees = async () => {
    try {
      setEmployeeSyncing(true);
      const response = await fetch(
        `${envEndPoints().baseUrl}/Employee/syncEmployees`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setLastEmployeeSynced(formatDate(new Date().toLocaleString()));
        alert("Employees synced successfully.");
        window.location.href = '/';
      } else {
        throw new Error("Failed to sync employees.");
      }
    } catch (error) {
      console.error("Error syncing employees:", error.message);
      alert("Failed to sync employees. Please try again later.");
    } finally {
      setEmployeeSyncing(false);
    }
  };

  const handleSyncSchedules = async () => {
    setScheduleSyncing(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Clinics: selectedClinics })
      };
      const response = await fetch(`${envEndPoints().baseSyncUrl}/Schedule/syncSchedules`, requestOptions);
      if (response.ok) {
        setLastScheduleSynced(new Date().toLocaleString());
        alert("Schedules synced successfully for selected clinics.");
      } else {
        throw new Error("Failed to sync schedules.");
      }
    } catch (error) {
      console.error("Error syncing schedules:", error.message);
      alert("Failed to sync schedules. Please try again later.");
    } finally {
      setScheduleSyncing(false);
      window.location.href = '/';
    }
  };


  return (
    <Box width="100%">
      <Grid container>
        <Grid item xs={12} sx={{ p: 4 }}>
          <h1>At a glance</h1>
        </Grid>

        <Grid item xs={12} md={4} sx={{ p: 2 }}>
          <Card>
            <Link to="/Clinics" style={{ textDecoration: "none" }}>
              <CardHeader
                title="Clinics"
                subheader="View all clinics"
                sx={{
                  p: 2,
                  backgroundColor: "var(--light-blue);",
                  color: "white",
                }}
              />
            </Link>

            <Grid container sx={{ p: 2 }}>
              <Grid item xs={12}>
                <h2 className="matched">{clinicDashboardDetails?.ClinicMatched}</h2>
                <p className="dashDesc">Clinics are linked</p>
              </Grid>
              <Grid item xs={12}>
                <h2 className="notmatched">{clinicDashboardDetails?.ClinicUnmatched}</h2>
                <p className="dashDesc">Clinics haven't been linked</p>
              </Grid>
            </Grid>
            <hr />
            <Grid container sx={{ p: 2, pt: 0, pb: 4 }}>
              <Grid item xs={12}>
                <p>Last Synced: {lastClinicSynced ? lastClinicSynced: "Not synced"}</p>

                {clinicSyncing ? (
                  "Syncing...   (The data that you see might be inaccurate)"
                ) : (
                  <Button
                    size="lg"
                    variant="primary"
                    onClick={handleSyncClinics}
                    disabled = {employeesSyncing || clinicSyncing || scheduleSyncing}
                  >
                    Sync Now
                  </Button>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} sx={{ p: 2 }}>
          <Card>
            <Link to="/EmployeePage" style={{ textDecoration: "none" }}>
              <CardHeader
                title="Employees"
                subheader="View all employees"
                sx={{
                  p: 2,
                  backgroundColor: "var(--light-blue);",
                  color: "white",
                }}
              />
            </Link>
            <Grid container sx={{ p: 2 }}>
              <Grid item xs={12}>
                <h2 className="matched">{employeeDashboardDetails?.EmployeeMatched}</h2>
                <p className="dashDesc">Employees are linked</p>
              </Grid>
              <Grid item xs={12}>
                <h2 className="notmatched">{employeeDashboardDetails?.EmployeeUnmatched}</h2>
                <p className="dashDesc">Employees haven't been linked</p>
              </Grid>
            </Grid>

            <hr />
            <Grid container sx={{ p: 2, pt: 0, pb: 4 }}>
              <Grid item xs={12}>
                <p>Last Synced: {lastEmployeeSynced || "Not synced"}</p>
                {employeesSyncing ? (
                  "Syncing... \n (The data that you see might be inaccurate)"
                ) : (
                  <Button
                    size="lg"
                    variant="primary"
                    onClick={handleSyncEmployees}
                    disabled = {employeesSyncing || clinicSyncing || scheduleSyncing}
                  >
                    Sync Now
                  </Button>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} sx={{ p: 2 }}>
          <Card>
            <Link to="/schedules" style={{ textDecoration: "none" }}>
              <CardHeader
                title="Schedules"
                subheader="View all schedules"
                sx={{
                  p: 2,
                  backgroundColor: "var(--light-blue);",
                  color: "white",
                }}
              />
            </Link>
            <Grid container sx={{ p: 2 }}>
              <Grid item xs={12}>
                <h2 className="matched">{scheduleDashboardDetails?.ScheduleSynced}</h2>
                <p className="dashDesc">Schedules are linked</p>
              </Grid>
              <Grid item xs={12}>
                <h2 className="notmatched">{scheduleDashboardDetails?.ScheduleNotSynced}</h2>
                <p className="dashDesc">Schedules haven't been linked</p>
              </Grid>
            </Grid>
            <hr />
            <Grid container sx={{ p: 2, pt: 0, pb: 4 }}>
              <Grid item xs={12}>
                <p>Last Synced: {lastScheduleSynced || "Not synced"}</p>

                {scheduleSyncing ? (
                  "Syncing... \n (The data that you see might be inaccurate)"
                ) : (
                  <Button
                    size="lg"
                    variant="primary"
                    onClick={handleSyncSchedules}
                    disabled = {employeesSyncing || clinicSyncing || scheduleSyncing}
                  >
                    Sync Now
                  </Button>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {/* {(clinicSyncing || employeesSyncing || scheduleSyncing) && (
        <div className="sync-overlay">
          <Typography variant="body1" className="progress-text">
            Sync in progress.
          </Typography>
          <LinearProgress className="progress-bar" />
          <br></br>
        </div>
      )} */}
    </Box>
  );
};

export default DashboardPage;
