import { call, put } from 'redux-saga/effects';
import {
  createTreatmentPlan,
  approveTreatmentPlan,
  getTreatmentPlan,
  patientConsent,
  declineTreatmentPlan,
  generateDocuments
} from './../api/treatmentApi';
import { joinQueue, assignPrescriber, unassignPrescriber } from '../api/communicationApi';
import {
  navigateAddTreatmentSuccess,
  createTreatmentPlanSuccess,
  updateTreatmentPlanSuccess,
  approveTreatmentPlanSuccess,
  getTreatmentPlanSuccess,
  patientConsentSuccess,
} from '../reducers/treatmentPlanSlice';
import { assignPresciberSuccess, joinQueueSuccess } from '../reducers/videoConsultSlice';
import { setLoader, resetLoader } from '../reducers/loaderSlice';
import store from '../store';
import { confirmTreatment , getAppointment} from '../api/appointmentsApi';
import { appointmentDetails } from '../receivers/appointmentDetailsReceiver';
import {
  getAppointmentSuccess,
  getAppointmentDetailsSuccess
} from '../reducers/appointmentSlice';
import { saveUserSign } from '../api/userApi';

export function* navigateToTreatmentHandler(
  action: any
): Generator<any, any, any> {
  const {
    patientId,
    appointmentId,
    treatmentId,
    clinicId,
    navigate,
    redirectUrl,
    patientName,
  } = action.payload;

  yield put(setLoader());

  yield put(
    navigateAddTreatmentSuccess({
      patientId: patientId,
      appointmentId: appointmentId,
      clinicId: clinicId,
      patientName: patientName,
    })
  );
  navigate(redirectUrl);
  yield put(resetLoader());
}

export function* createTreatmentHandler(action: any): Generator<any, any, any> {
  let { data, navigate, redirectUrl } = action.payload;

  yield put(setLoader());
  const { response } = yield call(createTreatmentPlan, { data: data });
  if (response) {
    if (response) {
      data.id = response.data.Id;

      yield put(createTreatmentPlanSuccess({ ...data }));
      yield put(resetLoader());

      if (redirectUrl === "") {
        if (data.treatmentInfo?.packageType === "injectibles") {
          const treatmentInfoCheck =
            data.treatmentInfo?.antiWrinkle.every(
              (aw: any) => !aw.productName
            ) &&
            data.treatmentInfo?.filler.every(
              (filler: any) =>
                !filler.areaName 
            ) &&
            data.treatmentInfo?.other.every(
              (other: any) => !other.productName
            );
      
          const antiWrinkleCheck =
            data.treatmentInfo?.antiWrinkle.every(
              (aw: any) =>
                !(!aw.productName &&
                !aw.units) &&
                (!aw.treatmentAreas ||
                  !Object.values(aw.treatmentAreas).some((value) => value === true))
            );
      
          const fillerCheck = data.treatmentInfo?.filler.every(
            (filler: any) =>
              filler.areaName !== "" &&
              (filler.products
                ? filler.products.every((product: any) => product.units === "")
                : true)
          );

          const otherCheck = data.treatmentInfo?.other.every(
            (other: any) => other.productName !== "" && !(other.units !== "")
          );
      
          redirectUrl = treatmentInfoCheck || antiWrinkleCheck || fillerCheck || otherCheck ? "" : "/treatmentplan/review";
        } else {
          redirectUrl = "/treatmentplan/consent";
        }
      }
      navigate(redirectUrl);
      
    }
  }
}

export function* updateTreatmentHandler(action: any): Generator<any, any, any> {
  const { data, navigate, redirectUrl } = action.payload;

  yield put(setLoader());
  const { response } = yield call(createTreatmentPlan, { data: data });
  if (response) {
    if (response) {
      data.id = response.data.Id;
      yield put(updateTreatmentPlanSuccess({ ...data }));
      yield put(resetLoader());
    }
  }
}

export function* rejoinHandler(
  action: any
): Generator<any, any, any> {
  const { data, navigate, redirectUrl } = action.payload;

  yield put(setLoader());
  let url = redirectUrl;

      const resUnassignPrescriber = yield call(unassignPrescriber,{treatmentPlanId: data.id,})
      if(resUnassignPrescriber){
          const resAssignPrescriber = yield call(assignPrescriber, {
            treatmentPlanId: data.id,
          });

          if (resAssignPrescriber) {
            yield put(
              assignPresciberSuccess({ ...resAssignPrescriber.response.data })
            );

            if (resAssignPrescriber.response.data.isPrescriberAssigned) {
              url = '/videoconsult/conduct';
            }
          }
        }

      yield put(resetLoader());
      navigate(url);
}

export function* approveTreatmentHandler(
  action: any
): Generator<any, any, any> {
  const { data, navigate, redirectUrl } = action.payload;
  const request = {
    treatmentPlanId: data.id,
    treatmentStatusId: data.treatmentStatusId,
    approvedSignature: data.approvedSignature,
    comments: data.approvalComment,
    placeOfPractice: data.placeOfPractice || '',
    prescriberNumber: data.prescriberNumber || '',
    userId: data.userid,
  };

  yield put(setLoader());
  let url = redirectUrl;

  let userSignRequest = {
    userId: data.userid,
    data: {
      Sign : request.approvedSignature
    },
  }

  const { userResponse } = yield call(saveUserSign, { ...userSignRequest }); 

  const { response } = yield call(approveTreatmentPlan, { data: request });

  if (response) {
    if (response.data.treatmentStatusId === 7) {
      yield put(approveTreatmentPlanSuccess({ ...response.data }));
      yield put(resetLoader());
      navigate(url);
    } else {
      yield put(approveTreatmentPlanSuccess({ ...response.data }));
      const autenticationSate = store.getState().authentication;

      if (autenticationSate.role.trim().toLowerCase() === 'nurseinjector') {
        const responseQueue = yield call(joinQueue, {
          treatmentPlanId: data.id,
          userId: autenticationSate.id,
        });

        if (responseQueue) {
          yield put(
            joinQueueSuccess({ ...responseQueue.response.data })
          );
          const resAssignPrescriber = yield call(assignPrescriber, {
            treatmentPlanId: data.id,
          });

          if (resAssignPrescriber) {
            yield put(
              assignPresciberSuccess({ ...resAssignPrescriber.response.data })
            );

            if (resAssignPrescriber.response.data.isPrescriberAssigned) {
              url = '/videoconsult/conduct';
            }
          }
        }
      }
      else if(autenticationSate.role.trim().toLowerCase() === 'nursepractitioner')
      {
        url = '/treatmentplan/consent';
      }

      yield put(resetLoader());
      navigate(url);
    }
  }
}

export function* getTreatmentHandler(action: any): Generator<any, any, any> {
  const { data, navigate, redirectUrl } = action.payload;
  yield put(setLoader());
  const { response } = yield call(getTreatmentPlan, { id: data.id });
  if (response) {
    yield put(getTreatmentPlanSuccess({ ...response.data }));
    yield put(resetLoader());
  }
}

export function* patientConsentHandler(action: any): Generator<any, any, any> {
  const { data, navigate, redirectUrl } = action.payload;

  const request = {
    treatmentPlanId: data.treatmentPlanId,
    treatmentStatusId: data.treatmentStatusId,
    approvedSignature: data.approvedSignature,
    comments: data.comments,
    userId: data.userid,
  };
  yield put(setLoader());
  let url = redirectUrl;
  const { response } = yield call(patientConsent, { data: request });
  if (response) {
    yield put(
      patientConsentSuccess({ treatmentStatusId: data.treatmentStatusId })
    );

    const responseConfrimTrteatment = yield call(confirmTreatment, {
      appointmentId: data.appointmentId,
      treatmentPlanId: data.treatmentPlanId,
    });

    if(responseConfrimTrteatment)
    {
      const appointmentrResponse = yield call(getAppointment, { id:  data.appointmentId });
      if(appointmentrResponse)
      {

        yield put(getAppointmentDetailsSuccess(appointmentDetails(appointmentrResponse.response.data)));
      
      }
    }

    yield put(resetLoader());
    navigate(url);
  }
}

export function* declineTreatmentHandler(
  action: any
): Generator<any, any, any> {
  const { data, navigate, redirectUrl } = action.payload;
  const request = {
    treatmentPlanId: data.id,
    treatmentStatusId: data.treatmentStatusId,
    approvedSignature: data.approvedSignature,
    comments: data.approvalComment,
    placeOfPractice: data.placeOfPractice || '',
    prescriberNumber: data.prescriberNumber || '',
    userId: data.userid,
  };

  yield put(setLoader());
  let url = redirectUrl;
  const { response } = yield call(declineTreatmentPlan, { data: request });

  if (response) {
    yield put(approveTreatmentPlanSuccess({ ...response.data }));
    yield put(resetLoader());
    navigate(url);
  }
}

export function* generateDocumentsHandler(action: any): Generator<any, any, any> {
  const { data } = action.payload;
  yield put(setLoader());
  const { response } = yield call(generateDocuments, { id: data.id });
  if (response) {
    yield put(resetLoader());
  }
}
