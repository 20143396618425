import "./Button.css";

const Button = (props) => {
  const {
    size = "",
    variant = "primary",
    navigateTo,
    newTab = false,
    isNavigation = false,
    margin = "",
    ...otherProps
  } = props;

  let btnSize = size !== "" ? `btn-${size}` : "";
  let btnMargin = margin !== "" ? `m-${margin}` : ""; // This assumes you have margin classes like m-1, m-2 etc.

  // If it's a navigation button, render an anchor tag styled as a button
  if (isNavigation && navigateTo) {
    return (
      <a
        href={navigateTo}
        target={newTab ? "_blank" : "_self"}
        rel={newTab ? "noopener noreferrer" : undefined}
        className={`btn btn-${variant} ${btnSize} ${btnMargin}`}  // Add margin class here
        {...otherProps}
      />
    );
  }

  // Else, render the button normally
  return <button className={`btn btn-${variant} ${btnSize} ${btnMargin}`} {...otherProps} />;  // Add margin class here
};

export default Button;
