import { get, put, del } from './restApi';
import { envEndPoints } from '../config';

export const getClinics = () =>
  get({
    url: `${envEndPoints().baseUrl}/clinic/getClinics`,
  });

  export const syncClinics = () =>
  get({
    url: `${envEndPoints().baseUrl}/clinic/syncclinics`,
  });
